import {
  Stack,
  MenuItem,
  Box,
  Paper,
  Typography,
  FormControl,
} from "@mui/material"
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { useCallback, useContext, useEffect, useState } from "react"
import React from "react"
import { TSale } from "../../../../../core/types/Sale"
import {
  IProductPricePaymentInstallment,
  TPaymentMethodEnum,
} from "../../../../../core/types/Product"
import { CreditCard } from "../../../../../core/types/CreditCard"
import NumberFunctions from "../../../../../core/functions/NumberFunctions"
import { creditsCards } from "../../../../../core/constants"
import TextFieldRequired from "../../../../Inputs/TextFieldRequired"
import SelectRequired from "../../../../Inputs/SelectRequired"
import { CheckoutContext } from "../../../../../core/contexts/CheckoutContext"
import Convertions from "../../../../../core/functions/convertions"
import { maskCardNumber, maskMonthYear } from "../../../../../masks/masks"
import { checkoutPayment4Style } from "./PaymentConsts"
import PriceInput from "../../../../Inputs/PriceInput"
import OrderController from "../../../../../core/controllers/OrderController"
import { IErrorResponse } from "../../../../../core/types/ErrorResponse"
import { ICalculateInstallment } from "../../../../../core/types/Order"

export interface ICreditCardPaymentPrice4Props {
  visible: boolean
  title?: string
  control: Control<TSale, any>
  errors: FieldErrors<TSale>
  installments: IProductPricePaymentInstallment[] | undefined
  index: number
  isMultiple?: boolean
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  clearErrors: UseFormClearErrors<TSale>
}

const CreditCardPaymentPrice4 = (props: ICreditCardPaymentPrice4Props) => {
  const {
    visible,
    title,
    control,
    installments,
    index,
    isMultiple = false,
    watch,
    setValue,
    clearErrors,
  } = props
  const { productPrice: data, installment } = useContext(CheckoutContext)

  const [creditCard, setCreditCard] = useState<CreditCard | undefined>()
  const [errorValue, setErrorValue] = useState("")
  const [creditCardInstallments, setCreditCardInstallments] = useState<
    Array<IProductPricePaymentInstallment>
  >([])

  const saleValueTotal = watch("payment.total")
  const payments = watch("payment.payments")
  const paymentCardValue = watch(`payment.payments.${index}.value`)
  const paymentCardNumber = watch(
    `payment.payments.${index}.creditCardData.number`
  )
  const paymentCardValidate = watch(
    `payment.payments.${index}.creditCardData.validate`
  )
  const paymentCardCVV = watch(`payment.payments.${index}.creditCardData.cvv`)
  const paymentInstallment = watch("payment.installment")

  const firstPurchasePrice = data?.firstPurchasePrice ?? 0
  const isFirstPurchasePrice = firstPurchasePrice > 0
  const frequencyDescription = Convertions.productFrequencyToType(
    data?.frequency
  )
  const complement = isFirstPurchasePrice
    ? ` para o primeiro ${frequencyDescription.toLowerCase()}`
    : ""
  const installmentCurrent = installment?.original?.find(
    (inst) => inst.installmentNumber === paymentInstallment
  )

  const getInstallments = (
    installmentsData: IProductPricePaymentInstallment[] | undefined
  ) => {
    const list = installmentsData?.sort((a, b) =>
      a.installmentNumber > b.installmentNumber ? -1 : 1
    )
    const items =
      list?.map((x) => {
        const key = x.installmentNumber
        const value = NumberFunctions.formatMoneyDefault(x.value)
        const installmentFees = x.isInterestFree ? " (sem juros)" : ""
        const label = `${key}x de ${value}${complement}${installmentFees}`
        return {
          key,
          label,
        }
      }) || []

    return items
  }

  const itensMenu =
    creditCardInstallments.length > 0
      ? getInstallments(creditCardInstallments)
      : getInstallments(installments)

  const getValidate = (value: string) => {
    const cardValidate = value.split("/")
    if (cardValidate.length > 1) {
      const month = Number(cardValidate[0])
      const year = Number(cardValidate[1])
      return { month, year }
    }
    return undefined
  }

  const handleLoadCalculateInstallments = useCallback(
    async (newValue: number) => {
      try {
        const response = await OrderController.calculeIsntallments({
          priceId: data?.id ?? "",
          newValue,
        })
        const responseError = response as IErrorResponse
        const responseData = response as ICalculateInstallment

        if (responseError.code) {
        } else {
          setValue(
            `payment.payments.${index}.installments`,
            responseData.installments
          )
          setCreditCardInstallments(responseData.installments)
        }
      } finally {
      }
    },
    [data, setValue, setCreditCardInstallments]
  )

  const handleChangeCardValidate = (value?: any) => {
    if (!value) return true
    const validate = getValidate(value)
    if (validate) {
      try {
        const now = new Date()
        const nowMonth = now.getMonth() + 1
        const nowYear = Number(now.getFullYear().toString().slice(2))
        if (validate.year < nowYear) return false
        if (validate.month < nowMonth && validate.year === nowYear) return false
      } catch (e) {
        console.error(e)
      }
      return validate.month > 0 && validate.month <= 12
    }
    return value && value !== ""
  }

  const nextInput = () => {
    const inputs = Array.prototype.slice.call(
      document.querySelectorAll("input")
    )
    const currInput = document.activeElement
    const currInputIndex = inputs.indexOf(currInput)
    const nextinputIndex = (currInputIndex + 1) % inputs.length
    const input = inputs[nextinputIndex]
    if (input) {
      input.focus()
    }
  }

  useEffect(() => {
    var exists = false
    creditsCards.map((cc) => {
      if (cc.compare(paymentCardNumber)) {
        setCreditCard(cc)
        exists = true
      }
      return true
    })
    if (!exists) {
      setCreditCard(undefined)
    }
  }, [paymentCardNumber])

  useEffect(() => {
    if ((paymentCardCVV || "").length === 4) {
      // comentado pois foi solicitado a alteração para abrir automático as parcelas
      // depois foi pedido para remover, caso seja necessário voltar descomentar
      //handleOpenInstallment();
      document.getElementById("buttonCommit")?.focus()
    }
  }, [paymentCardCVV])

  useEffect(() => {
    const cardNumberComplete = maskCardNumber(paymentCardNumber)
    setValue(
      `payment.payments.${index}.creditCardData.number`,
      cardNumberComplete
    )
    if (cardNumberComplete.length > 18) {
      nextInput()
    }
  }, [paymentCardNumber, setValue])

  useEffect(() => {
    const cardValidateComplete = maskMonthYear(paymentCardValidate)
    setValue(
      `payment.payments.${index}.creditCardData.validate`,
      cardValidateComplete
    )
    if (cardValidateComplete.length > 4) {
      const cardValidate = getValidate(cardValidateComplete)
      if (cardValidate) {
        setValue(
          `payment.payments.${index}.creditCardData.month`,
          cardValidate.month
        )
        setValue(
          `payment.payments.${index}.creditCardData.year`,
          cardValidate.year
        )
      }
      nextInput()
    }
  }, [paymentCardValidate, setValue])

  useEffect(() => {
    if (index === 0 && paymentCardValue) {
      if (payments.length > 1) {
        const diff = saleValueTotal - Number(paymentCardValue)
        setValue("payment.payments.1.value", Math.max(0, diff))
      }
    }

    if ((paymentCardValue ?? 5) < 5) {
      setErrorValue(
        `O valor precisa ser maior que R$ ${NumberFunctions.formatMoneyDefault(
          5
        )}`
      )
    }

    handleLoadCalculateInstallments(paymentCardValue ?? 0)
  }, [paymentCardValue])

  useEffect(() => {
    if (index === 0 && saleValueTotal) {
      if (payments.length > 1) {
        setValue(`payment.payments.0.value`, 5)
        setValue(`payment.payments.1.value`, saleValueTotal - 5)
      } else if (payments.length === 1) {
        setValue(`payment.payments.0.value`, saleValueTotal)
      }
    }
  }, [saleValueTotal])

  useEffect(() => {
    handleLoadCalculateInstallments(paymentCardValue ?? 0)
  }, [])

  return (
    <Box key={index.toString()} sx={{ display: visible ? "block" : "none" }}>
      <Paper
        elevation={0}
        sx={{ borderRadius: "8px", backgroundColor: "#F2F2FF" }}
      >
        <Stack direction="column" spacing={2} sx={{ p: "20px" }}>
          {title && isMultiple && (
            <Stack direction="row" spacing={1} alignItems="center">
              {checkoutPayment4Style[TPaymentMethodEnum.CreditCard].icon[0]}
              <Typography
                variant="body1"
                fontSize="14px"
                fontWeight={600}
                lineHeight="18px"
              >
                {title}
              </Typography>
            </Stack>
          )}
          {isMultiple && (
            <FormControl fullWidth>
              <PriceInput
                label="Valor a pagar no cartão"
                value={!paymentCardValue ? 0 : paymentCardValue}
                error={errorValue !== ""}
                InputProps={{
                  inputProps: { min: 5 },
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: errorValue !== "" ? "red" : "#BCC1FF",
                      borderWidth: "2px",
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: errorValue !== "" ? "red" : "#BCC1FF",
                      borderWidth: "2px",
                    },
                  },
                }}
                onChange={(value) => {
                  setValue(`payment.payments.${index}.value`, value)
                  setErrorValue("")
                }}
                disabled={index > 0}
              />
              <Typography
                lineHeight="14px"
                fontSize="12px"
                variant="body2"
                sx={{
                  color: "#5D5E61",
                  pt: 0.5,
                }}
              >
                O valor mínimo é R$ {NumberFunctions.formatMoneyDefault(5)}
              </Typography>
              {errorValue !== "" && (
                <Typography variant="overline" color={"red"}>
                  {errorValue}
                </Typography>
              )}
            </FormControl>
          )}
          <TextFieldRequired
            control={control}
            fieldName={`payment.payments.${index}.creditCardData.name`}
            label="Nome impresso no cartão"
            placeholder="Digite o nome impresso no cartão"
            clearErrors={clearErrors}
            required={visible}
            inputProps={{
              "data-cy": "cardName",
              "data-testid": "cardName",
            }}
            dataCyErrorLabel="cardNameError"
            backgroundColorTitle="#F2F2FF"
          />
          <TextFieldRequired
            control={control}
            fieldName={`payment.payments.${index}.creditCardData.number`}
            label="Número do cartão"
            placeholder="Digite somente números"
            inputProps={{
              inputMode: "numeric",
              maxLength: 19,
              "data-cy": "cardNumber",
              "data-testid": "cardNumber",
            }}
            dataCyErrorLabel="cardNumberError"
            backgroundColorTitle="#F2F2FF"
            clearErrors={clearErrors}
            required={visible}
            endAdornment={
              creditCard && (
                <img
                  src={creditCard.getImageUrl()}
                  alt="credit card"
                  style={{ height: 30, objectFit: "contain" }}
                />
              )
            }
          />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            sx={{ width: "100%" }}
          >
            <TextFieldRequired
              control={control}
              fieldName={`payment.payments.${index}.creditCardData.validate`}
              label="Validade do cartão (MM/AA)"
              placeholder="MM/AA"
              inputProps={{
                inputMode: "numeric",
                maxLength: 5,
                "data-cy": "cardValidate",
                "data-testid": "cardValidate",
              }}
              dataCyErrorLabel="cardValidateError"
              backgroundColorTitle="#F2F2FF"
              clearErrors={clearErrors}
              required={visible}
              validate={handleChangeCardValidate}
            />
            <TextFieldRequired
              control={control}
              fieldName={`payment.payments.${index}.creditCardData.cvv`}
              label="Cód. de segurança (CVV)"
              placeholder="999"
              inputProps={{
                inputMode: "numeric",
                maxLength: 4,
                "data-cy": "cardCCV",
                "data-testid": "cardCCV",
              }}
              dataCyErrorLabel="cardCCVError"
              backgroundColorTitle="#F2F2FF"
              clearErrors={clearErrors}
              required={visible}
            />
          </Stack>
          <Box>
            <SelectRequired
              control={control}
              fieldName={`payment.installment`}
              label="Nº de parcelas"
              required={visible}
              backgroundColorTitle="#F2F2FF"
              disabled={index > 0}
            >
              {itensMenu.map((option) => (
                <MenuItem key={`${index}-${option.key}`} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectRequired>
            {isFirstPurchasePrice && (
              <Typography fontSize="14px" lineHeight="16px" sx={{ mt: 1 }}>
                Renovação a partir da 2ª cobrança: {paymentInstallment}x de{" "}
                {NumberFunctions.formatMoneyDefault(installmentCurrent?.value)}{" "}
                {frequencyDescription.toLowerCase()}
              </Typography>
            )}
          </Box>
        </Stack>
      </Paper>
    </Box>
  )
}

export default CreditCardPaymentPrice4
